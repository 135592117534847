import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { makePath } from '../../helpers/path'
import { Prefix } from '../../@types/prefix'
import { useLocation } from 'react-router'
// import { useLocation } from 'react-router'

interface BottomBarProps {
	prefix?: Prefix
	showMenuSupport?: boolean
	alert: () => void
	modalPromotions: () => void
	modalSupport: () => void
}
const BottomBar: FC<BottomBarProps> = ({ prefix, showMenuSupport, alert, modalPromotions, modalSupport }) => {
	const loc = useLocation()
	const playgameMotion = prefix?.playgameMotion === "" || prefix?.playgameMotion === "dance" ? "dance" : prefix?.playgameMotion;

	return (
		<div className={`bottombar-mobile ${prefix?.prefix === 'VR88' ? 'bottombar-dark' : ''}`}>
			<ul className="nav-menu">
				<li className="nav-item">
					<Link
						// onClick={alert}
						to={makePath('/home')}
						className={`nav-link ${loc.pathname === '/home' && 'active'} `}
					>
						<img src={`/icons/new/home.svg`} alt="icon" />
						<span>หน้าหลัก</span>
					</Link>
				</li>
				<li className="nav-item">
					<Link
						to={makePath('/login')}
						// onClick={modalPromotions}
						className={`nav-link`}
					>
						<img src={`/icons/new/promotion.svg`} alt="icon" />
						<span>โปรโมชั่น</span>
					</Link>
				</li>
				<li className="nav-item middle-item">
					<Link
						to={makePath('/login')}
						className={`nav-link`}
					>
						<div className={`icon-button-container ${playgameMotion}`}>
							<div className="icon-button">
								<div className="icon-container" />
							</div>
							{prefix?.playgameImage ?
								<img className="icon-center" src={prefix?.playgameImage} alt="icon" /> :
								<img className="icon-center" src={`/icons/new/play_new.png`} alt="icon" />
							}
						</div>
						<span>เข้าสู่ระบบ</span>
					</Link>
				</li>
				<li className="nav-item">
					<Link
						to={(!prefix?.functions.hasOwnProperty("offRegister") || !prefix?.functions['offRegister']) ? makePath('/register') : makePath('/login')}
						className={`nav-link`}
					>
						<img src={`/icons/new/account.svg`} alt="icon" />
						<span>สมัครสมาชิก</span>
					</Link>
				</li>
				{/* check link support */}
				{!showMenuSupport && (
					<li className="nav-item">
						<Link
							to="#"
							onClick={modalSupport}
							className={`nav-link`}
						>
							<img src={`/icons/new/help.svg`} alt="icon" />
							<span>ติดต่อเรา</span>
						</Link>
					</li>
				)}
				{prefix?.lineLink && showMenuSupport ? (
					<li className="nav-item">
						<Link
							to={{ pathname: prefix.lineLink }}
							target="_blank"
							className={`nav-link`}
						>
							<img src={`/icons/new/help.svg`} alt="icon" />
							<span>ติดต่อเรา</span>
						</Link>
					</li>
				) : (!prefix?.lineLink && showMenuSupport &&
					<li className="nav-item">
						<Link
							to={makePath('/login')}
							className={`nav-link`}
						>
							<img src={`/icons/new/help.svg`} alt="icon" />
							<span>ติดต่อเรา</span>
						</Link>
					</li>
				)}
			</ul>
		</div>
	)
}

export default BottomBar
