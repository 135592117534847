import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router'
import { ApplicationState } from '../store'
import { Banks, getBankColor, getBankName } from '../helpers/bank'
import { Button, Col, Divider, Modal, Radio, Row, Space, Typography, Upload } from 'antd'
import type { RadioChangeEvent } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { InboxOutlined } from '@ant-design/icons'
import api from '../helpers/api'
import { APIResponse } from '../@types/api'
import { requestUser } from '../store/user/user.actions'
import { toast } from 'react-toastify'
import { requestDepositTo } from '../store/deposit/deposit.actions'

const { Text } = Typography

const UploadSlip = () => {
	// Upload
	const prefix = useSelector((state: ApplicationState) => state.prefix.info)
	// const userState = useSelector((state: ApplicationState) => state.user)
	// const info = userState.info

	const [fileList, setFileList] = useState<UploadFile[]>([])
	const [previewVisible, setPreviewVisible] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [previewTitle, setPreviewTitle] = useState('')
	const [uploading, setUploading] = useState(false)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(requestDepositTo())
	}, [dispatch])

	const getBase64 = (file: RcFile): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result as string)
			reader.onerror = error => reject(error)
		})

	const handleCancel = () => setPreviewVisible(false)

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile)
		}

		setPreviewImage(file.url || (file.preview as string))
		setPreviewVisible(true)
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
	}

	const beforeUploadPicture = (file: any) => {
		const isJpg = file.type === 'image/jpeg'
		const isPng = file.type === 'image/png'


		const isLt2M = file.size / 1024 / 1024 < 2

		if (!isJpg && !isPng) {
			toast.error('คุณสามารถอัปโหลดไฟล์ JPG เท่านั้น!')
			return Upload.LIST_IGNORE
		}

		if (!isLt2M) {
			toast.error('รูปภาพต้องมีขนาดเล็กกว่า 2MB!')
			return Upload.LIST_IGNORE
		}

		return false;
	}

	const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList as any)

	const handleUpload = async () => {
		const formData = new FormData()

		formData.append('file', fileList[0]?.originFileObj as RcFile)
		formData.append('bankNumber', bankNumber)
		setUploading(true)

		if (fileList.length > 0 && formData.get('bankNumber')) {
			try {
				const res = await api.post<void, APIResponse>('/deposit/scan-qrcode', formData)

				dispatch(requestUser())
				setFileList([])
				toast.success(res.message)
			} catch ({ message }) {
				setFileList([])
				toast.error(message as string)
			} finally {
				setUploading(false)
			}
		} else {
			setUploading(false)
			toast.error('กรุณาใส่ข้อมูลให้ครบถ้วน')
		}
	}

	const uploadButton = (
		<>
			<div>
				<Text><InboxOutlined style={{ marginBottom: 4, fontSize: 40 }} /></Text>
			</div>
			<Text><small>คลิกหรือลากสลิปโอนเงินมาที่บริเวณนี้เพื่ออัปโหลด</small></Text>
		</>
	)

	// Select Bank
	const [bankNumber, setBankNumber] = useState('')
	const onChange = (e: RadioChangeEvent) => setBankNumber(e.target.value)

	// Query Bank
	const depositToList = useSelector((state: ApplicationState) => state.deposit.depositToList)

	// filter bank truewallet
	const depositToBanks = depositToList.filter((bank) => bank.bank !== 'truewallet')
	// const isScb = depositToList.filter(bank => bank.bank === 'scb').length > 0
	const replaceText = (text: string): string => {
		return text.split(' ').map((word, index) => {
			if (index > 0 && word.length > 2) {
				return word.substring(0, 1) + '*'.repeat(word.length - 1);
			}
			return word;
		}).join(' ');
	}

	if (!prefix?.functions.scanqr) {
		return <Redirect to="/deposit" />
	}

	return (
		<>
			<Row justify="center">
				<Col xs={24} md={16} xl={12}>
					<div className="form-card">
						<div className="form-card-body">
							<Text>อัปโหลดสลิป</Text>
							<Divider />

							<div className="notice-card warning" style={{ marginBottom: 16 }}>
								<div className="notice-card-icon">
									<img src="/icons/new/notice.svg" alt="notice icon" />
								</div>
								<Text className="notice-card-text">
									หากพบปัญหาเงินเข้าระบบล่าช้า อัปโหลดสลิปโอนเงินเข้าระบบ <Text type="warning"><strong>เพื่อทำการเติมเงินเข้าระบบ</strong></Text>
								</Text>
							</div>
							<Text type="secondary" style={{ marginBottom: 8 }}>เลือกบัญชีที่เติมเงินเข้าไป</Text>
							<Radio.Group onChange={onChange} value={bankNumber} style={{ width: '100%', marginBottom: 24, marginTop: 8 }}>
								<Row gutter={[16, 16]}>
									{depositToBanks.map((bank, index) => (
										<Col xs={24} sm={24} md={24} key={index}>
											<Radio className="bank-radio" value={bank.accountNumber}>
												<Space direction='horizontal' size={1}>
													<div
														className="bank-card-logo-mini"
														style={{ boxShadow: `0 8px 12px -4px ${getBankColor(bank.bank)}` }}
													>
														<img
															src={Banks.filter(bankName => bankName.key === bank?.bank.toUpperCase())[0].image}
															alt={getBankName(bank.bank)}
														/>
													</div>
													<div className="bank-radio-info">
														<div><small>{getBankName(bank.bank)}</small></div>
														<div><b>{bank.accountNumber}</b></div>
														<div><small>{(prefix?.prefix === "AMBGAME" || prefix?.prefix === "ALD88" || prefix?.prefix === "WK89") ? replaceText(bank.accountName) : bank.accountName}</small></div>
													</div>
												</Space>
											</Radio>
										</Col>
									))}
								</Row>
							</Radio.Group>
							<div style={{ marginBottom: 24 }}>
								<Upload
									className="ant-upload-drag-custom"
									listType="picture-card"
									fileList={fileList}
									onPreview={handlePreview}
									onChange={handleChange}
									beforeUpload={beforeUploadPicture}
								>
									{fileList.length >= 1 ? null : uploadButton}
								</Upload>
							</div>
							<div className="btn-submit">
								<Button type="primary" shape="round" htmlType="submit" loading={uploading} onClick={handleUpload} block>
									อัปโหลดสลิป
								</Button>
							</div>
						</div>
					</div>
				</Col>
			</Row>
			<Modal open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
				<img alt={previewTitle} src={previewImage} style={{ width: '100%' }} />
			</Modal>
		</>

	)
}

export default UploadSlip
